<template>
  <v-card>
    <v-card-text>
      <v-data-table
        v-bind="dataTableAttrs"
        :headers="headersShown"
        :items="list"
        :loading="isLoading"
        :options.sync="options"
        :server-items-length="total"
        :items-per-page.sync="itemsPerPage"
        v-model="selectedItems"
        @update:items-per-page="handleItemsPerPageUpdated"
      >
        <template v-slot:top>
          <rs-table-title-bar
            v-if="$refs.headSearch"
            title="Bakım Tanımları"
            icon="mdi-spray-bottle"
            @clear-filters="$refs.headSearch.reset"
            @reload="loadList"
            :hide-edit="!can('edit-maintenance')"
            :edit-enabled="selectedItems.length === 1"
            @click:edit="handleEditClick"
            :search.sync="search.query"
            v-bind="titleBarAttrs"
            :add-route="
              can('edit-maintenance') && clusterId
                ? { name: 'other-definitions.maintenance.create' }
                : null
            "
          />
        </template>

        <!-- eslint-disable-next-line -->
        <template v-slot:body.prepend="{ headers }">
          <rs-table-head-search
            :headers="headers"
            :search.sync="search"
            ref="headSearch"
          />
        </template>

        <!-- eslint-disable-next-line -->
        <template v-slot:item.edit="{ item }">
          <router-link
            class="btn btn-icon btn-sm btn-clean"
            :to="{
              name: 'other-definitions.maintenance.edit',
              params: { id: item.id },
            }"
          >
            <i class="menu-icon mdi mdi-pencil"></i>
          </router-link>
        </template>

        <!-- eslint-disable-next-line -->
        <template v-slot:item.subject="{ value }">
          <rs-table-cell-string :value="value" :limit="20" />
        </template>

        <!-- eslint-disable-next-line -->
        <template v-slot:item.is_active="{ value }">
          <rs-table-cell-boolean :value="value" />
        </template>

        <!-- eslint-disable-next-line -->
        <template v-slot:item.description="{ value }">
          <rs-table-cell-string :limit="20" :value="value" />
        </template>
      </v-data-table>
    </v-card-text>
  </v-card>
</template>

<script>
import { mapGetters } from "vuex";

import { debounce } from "@/core/plugins/debounce";
import { filtersToURL, hasExport, hasPermissions } from "@/view/mixins";

export default {
  name: "MaintenanceList",
  mixins: [filtersToURL, hasExport, hasPermissions],
  computed: {
    ...mapGetters(["companyId", "clusterId", "reminderPeriodList"]),
  },
  watch: {
    options: {
      handler() {
        this.setURLParams();
      },
      deep: true,
    },
    search: {
      handler: debounce(function () {
        this.options.page = 1;
        this.setURLParams();
      }),
      deep: true,
    },
    clusterId() {
      this.loadList();
    },
    companyId() {
      this.loadList();
    },
  },
  data() {
    return {
      titleBarAttrs: {
        exportUrl: "maintenances",
        exportParams: this.getParams,
      },
      search: {
        is_active: true,
      },
      headers: [
        {
          text: "",
          value: "edit",
          searchable: false,
          sortable: false,
          width: "30px",
          align: "center",
          hide: () => !this.can("edit-maintenance"),
        },
        {
          text: "Bakım Türü",
          value: "maintenance_type",
          searchable: "text",
        },
        {
          text: "Bakım Tipi",
          value: "child_maintenance_type",
          searchable: "text",
        },
        {
          text: "Bakım Periyodu",
          value: "reminder_period",
          searchable: "multiselect",
          options: () => this.reminderPeriodList,
          searchValue: "reminder_period_id",
        },
        {
          text: "Konu",
          value: "subject",
          searchable: "text",
        },
        {
          text: "Bakım Giderecek",
          value: "provider",
          searchable: "text",
        },
        {
          text: "Açıklama",
          value: "description",
          searchable: "text",
        },
        {
          text: this.$t("labels.status"),
          value: "is_active",
          searchable: "isActive",
          align: "center",
        },
      ],
    };
  },
  methods: {
    getParams() {
      const params = Object.assign(
        this.buildSearchParams(this.search, this.options)
      );

      if (this.clusterId) {
        params.cluster_id = this.clusterId;
      }

      if (this.companyId) {
        params.company_id = this.companyId;
      }

      params.order_by = "id";

      return params;
    },
    loadList() {
      if (this.isLoading) {
        this.resetTable();
        return false;
      }

      this.resetTable();
      this.isLoading = true;

      const params = this.getParams();

      this.$api
        .query("maintenances", { params })
        .then((response) => {
          if (response.data && response.data.data) {
            this.list = response.data.data;
          }

          if (response.data && response.data.meta) {
            this.total = response.data.meta.total;
            this.itemsPerPage = response.data.meta.per_page;
            this.options.itemsPerPage = this.itemsPerPage;
            this.footTotals = response.data.meta.totals;
          }
        })
        .catch((error) => {
          this.handleError(error);
        })
        .finally(() => (this.isLoading = false));
    },
    handleEditClick() {
      if (this.isLoading || this.selectedItems.length !== 1) {
        return false;
      }

      this.$router.push({
        name: "other-definitions.maintenance.edit",
        params: {
          id: this.selectedItems[0].id,
        },
      });
    },
  },
  mounted() {
    this.generateExportColumns(this.headers);
  },
};
</script>
